import { forwardRef } from 'react';
import styled from 'styled-components';
import { TaskFormTextAreaInput } from '../form/TaskFormTextArea';

const TaskInputTextArea = styled(TaskFormTextAreaInput)`
  resize: none;
  max-height: 300px;
  overflow: hidden;
  width: 100%;
`;

interface AutoResizeTextAreaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {}

export const AutoResizeTextArea = forwardRef<HTMLTextAreaElement, AutoResizeTextAreaProps>((props, ref) => {
  const adjustHeight = (el: HTMLTextAreaElement) => {
    el.style.height = 'auto'; 
    el.style.height = `${el.scrollHeight}px`; 
  };

  return (
    <TaskInputTextArea
      ref={(el) => {
        if (el) {
          requestAnimationFrame(() => adjustHeight(el));
        }
      }}
      onInput={(e) => adjustHeight(e.target as HTMLTextAreaElement)} 
      rows={1}
      {...props}
    />
  );
});
