import { getDayjs, getDayjsInGMT, getDuration } from '../utils/dayjs.utils';
import { useGetCurrentEvent } from './api/useGetCurrentEvent';

export const useGetCurrentEventTimeInfo = () => {
  const { event, userEvent, isLoading } = useGetCurrentEvent();
  const now = getDayjs();
  if (event && userEvent) {
    const isUserManuallyFinished = userEvent.isManuallyFinished;
    const eventStartDate = getDayjsInGMT(event!.startTime);
    const eventEndDate = getDayjsInGMT(event!.endTime);
    const userEventActivationDate = getDayjsInGMT(userEvent!.startTime);
    const eventDurationAfterActivation = getDuration(event!.duration);
    const timeUntilEventEnds = eventEndDate.diff(now);
    const userTimerToCompleteActivatedEventRaw = eventDurationAfterActivation - now.diff(userEventActivationDate);
    const userTimerToCompleteActivatedEvent = Math.min(userTimerToCompleteActivatedEventRaw, timeUntilEventEnds);
    const isUserEventStillActive = userTimerToCompleteActivatedEvent > 0 && !isUserManuallyFinished;

    return {
      eventStartDate: eventStartDate,
      eventEndDate: eventEndDate,
      userEventActivationDate: userEventActivationDate,
      now: now,
      eventDurationAfterActivation: eventDurationAfterActivation,
      userTimerToCompleteActivatedEvent: userTimerToCompleteActivatedEvent,
      isUserEventStillActive: isUserEventStillActive,
      isLoadingTimeInfo: isLoading,
    };
  }

  return {
    eventStartDate: null,
    eventEndDate: null,
    userEventActivationDate: null,
    now: now,
    eventDurationAfterActivation: null,
    userTimerToCompleteActivatedEvent: null,
    isUserEventStillActive: true,
    isLoadingTimeInfo: isLoading,
  };
};
