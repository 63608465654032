import { Dayjs } from 'dayjs';
import { IProblem } from '../../../../../../core/types/problem.interface';
import React, { FC } from 'react';
import {
  StatsNameText,
  StatsRow,
  StatsValueText,
  TaskStatsCardHeading,
  TaskStatsCardInfoContainer,
  TaskStatsSmallCard,
} from '../../Styles';
import { getDayjsInGMT, getDueDate } from '../../../../../../utils/dayjs.utils';

export interface TaskDetailsProps {
  groupId?: string;
  startTime: string;
  endTime: string;
  problems: IProblem[];
}

const getTaskDetailsData = ({ groupId, startTime, endTime, problems }: TaskDetailsProps) => {
  return [
    {
      key: 'Кому назначено',
      // TODO get group name by IUniversityEvent.groupId
      value: 'Основы линейной алгебры для дизайнеров',
    },
    {
      key: 'Сроки выполнения',
      value: getDueDate(getDayjsInGMT(startTime), getDayjsInGMT(endTime)),
    },
    {
      key: 'Количество задач',
      value: problems.length,
    },
  ];
};

export const TaskDetails: FC<TaskDetailsProps> = props => {
  return (
    <TaskStatsSmallCard>
      <TaskStatsCardHeading>Сведения о задании</TaskStatsCardHeading>
      <TaskStatsCardInfoContainer>
        {getTaskDetailsData({ ...props }).map(item => {
          return (
            <StatsRow>
              <StatsNameText>{item.key}:</StatsNameText>
              <StatsValueText>{item.value}</StatsValueText>
            </StatsRow>
          );
        })}
      </TaskStatsCardInfoContainer>
    </TaskStatsSmallCard>
  );
};
