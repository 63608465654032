import { FC, useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import Skeleton from 'react-loading-skeleton';
import { useAuth } from '../../../../../../hooks/useAuth';
import { useNavigateTo } from '../../../../../../hooks/useNavigateTo';
import { Role } from '../../../../../../core/types/user.interface';
import { eventApi } from '../../../../../../core/api/rtk-query/event.api';
import { BlueButton } from '../../../button/BlueButton';
import { WhiteButton } from '../../../button/WhiteButton';
import { DisabledBlueButton } from '../../../button/DisabledBlueButton';
import { CardInfo } from '../../../card/CardInfo';
import { ProgressBar } from '../../../progressbar/ProgressBar';
import { EventCardProps } from './types';
import { BodyBigBoldText } from '../../../../../../styles/Text';
import { EventCardContainer, EventCardContent, EventCardDiv, EventDescription, ButtonArea } from './styles';

export const EventCard: FC<EventCardProps> = ({ id, name, info, description, point, buttons }) => {
  const [isStart, setIsStart] = useState<boolean>(false);
  const [startEvent] = eventApi.useStartEventMutation();

  const { role } = useAuth();
  const { data: userEvents, isLoading, isFetching } = eventApi.useGetUserEventsQuery();
  const navigateTo = useNavigateTo();

  const handleClick = () => {
    if (!userEvents?.map(userEvent => userEvent.eventId).includes(id)) {
      startEvent({ eventId: id, language: 'ru' })
        .unwrap()
        .then(() => {
          toast.success('Вы начали задание');
        });
      return setIsStart(true);
    }
    navigateTo(`tasks/${id}`);
  };

  const handleEditClick = () => {
    navigateTo(`tasks/edit/${id}`);
  };

  const handleShowClick = () => {
    navigateTo(`tasks/${id}`);
  };

  useEffect(() => {
    if (isStart && !isFetching) {
      navigateTo(`tasks/${id}`);
    }
  }, [isFetching]);

  return (
    <EventCardContainer>
      <EventCardContent>
        <EventCardDiv style={{ gap: '10px' }}>
          <BodyBigBoldText>{name}</BodyBigBoldText>
          <EventDescription>{description}</EventDescription>
          {info && <CardInfo conditions={info} />}
        </EventCardDiv>
        <EventCardDiv style={{ gap: '15px' }}>
          {point && <ProgressBar {...point} />}
          <ButtonArea style={{ gap: '10px' }}>
            {buttons.disabledBlueButtonText && (
              <DisabledBlueButton style={{ width: '250px', alignSelf: 'center' }}>
                {buttons.disabledBlueButtonText}
              </DisabledBlueButton>
            )}
            {buttons.whiteButtonText && (
              <WhiteButton
                style={{ width: '250px', alignSelf: 'center' }}
                onClick={() => {
                  role === Role.Student ? handleClick() : handleEditClick();
                }}
              >
                {buttons.whiteButtonText}
              </WhiteButton>
            )}
            {buttons.blueButtonText && (
              <BlueButton
                style={{ width: '250px', alignSelf: 'center' }}
                onClick={() => {
                  role === Role.Student ? handleClick() : handleShowClick();
                }}
                disabled={isLoading}
              >
                {buttons.blueButtonText}
              </BlueButton>
            )}
            {!buttons.whiteButtonText && !buttons.blueButtonText && !buttons.disabledBlueButtonText && (
              <div style={{ height: '36px', width: '250px' }}>
                <Skeleton />
              </div>
            )}
          </ButtonArea>
        </EventCardDiv>
      </EventCardContent>
    </EventCardContainer>
  );
};
